import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { KUDOS_MODULE_USAGE } from "modules/poc-dashboard/graphql/moduleusageQuery.gql";
import CommonTableView from "modules/poc-dashboard/components/common/tableView";
import { displayUtcToUserTimezone } from "modules/look"; 
import { ANALYTICS } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
 
const MappReviewContainer =(props)=>{
    const {client,permission}=props 
    const [data_list_loading,setDataListLoading] = React.useState(false)
    const [list,SetList] = React.useState([])
    const [page_info,SetPageInfo] = React.useState()
    const list_ref = React.useRef([])
    
    React.useEffect(()=>{
        list_ref.current = list
    },[list])
    
    const header_list =[
        {
            label:"Modules"
        },
        {
            label:"No. of employees"
        },
        {
            label:"Usage",
            custom_style:{width:"400px"}
        } 
    ]
    React.useEffect(()=>{
        if(permission?.required_permission){
            ModuleUsage({})
        }
    },[permission])

    const ModuleUsage=async(filter)=>{
        setDataListLoading(true)
        const { data } = await client.query({
            query: ANALYTICS,
            // variables: {...filter,first:10 },
            fetchPolicy: 'network-only'
        });
        if (data) { 
   
            const { coaching, feedback,kudos, idp, okr, readiness, soul, emp_count } = data?.analytics?.analyticsData
           
            let list = []
            if (soul) {
                let data_set = [
                    {
                        type:"label",
                        value:"SOUL"
                    },
                    {
                        type:"label",
                        value:`${soul?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(soul?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            } 
            if (idp) {
                let data_set = [
                    {
                        type:"label",
                        value:"IDP"
                    },
                    {
                        type:"label",
                        value:`${idp?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(idp?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            }
            if(okr){
                let data_set = [
                    {
                        type:"label",
                        value:"OKR"
                    },
                    {
                        type:"label",
                        value:`${okr?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(okr?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            }
            if(readiness){
                let data_set = [
                    {
                        type:"label",
                        value:"Readiness Zone"
                    },
                    {
                        type:"label",
                        value:`${readiness?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(readiness?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            }

            if(feedback){
                let data_set = [
                    {
                        type:"label",
                        value:"Feedback"
                    },
                    {
                        type:"label",
                        value:`${feedback?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(feedback?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            }
            if(coaching){
                let data_set = [
                    {
                        type:"label",
                        value:"Coaching Conversation"
                    },
                    {
                        type:"label",
                        value:`${coaching?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(coaching?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            }
            if(kudos){
                let data_set = [
                    {
                        type:"label",
                        value:"Kudos"
                    },
                    {
                        type:"label",
                        value:`${kudos?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(kudos?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            }
            const meeting = data?.analytics?.analyticsData["one-on-one"]
            if(meeting){
                let data_set = [
                    {
                        type:"label",
                        value:"1:1 Meeting"
                    },
                    {
                        type:"label",
                        value:`${meeting?.employee_used} Employees`
                    },
                    {
                        type:"progress-bar",
                        value:parseInt(meeting?.percentage?.replace("%", ""))
                    }
                ]
                list.push(data_set)
            } 
            
            SetList(list)
            setDataListLoading(false)
        }
    }

    const onPagination=()=>{
        if(page_info?.hasNextPage){
            ModuleUsage({after:page_info?.endCursor})
        }
    }

    return (
        <>
            <CommonTableView {...props} data_list_loading={data_list_loading} header_list={header_list} list={list} page_info={page_info} onPagination={onPagination} custom_height_class={"custom_height_class"}/>
        </>
    )
}

export default compose(withApollo)(MappReviewContainer)