import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { KUDOS_MODULE_USAGE } from "modules/poc-dashboard/graphql/moduleusageQuery.gql";
import CommonTableView from "modules/poc-dashboard/components/common/tableView";
import { displayUtcToUserTimezone } from "modules/look"; 
import moment from "moment";
 
const KudosModuleUsageContainer =(props)=>{
    const {client,permission}=props 
    const [data_list_loading,setDataListLoading] = React.useState(false)
    const [list,SetList] = React.useState([])
    const [page_info,SetPageInfo] = React.useState()
    const list_ref = React.useRef([])
    
    React.useEffect(()=>{
        list_ref.current = list
    },[list])
    
    const header_list =[
        {
            label:"Employee Name"
        },
        {
            label:"Issued Badge"
        },
        {
            label:"Received badge"
        },
        {
            label:"Last Accessed Date"
        }
    ]
    React.useEffect(()=>{
        if(permission?.required_permission){
            ModuleUsage({})
        }
    },[permission])

    const ModuleUsage=async(filter)=>{
        setDataListLoading(true)
        const { data } = await client.query({
            query: KUDOS_MODULE_USAGE,
            variables: {...filter,first:10 },
            fetchPolicy: 'network-only'
        });
        if (data?.allEmployees) { 
            let list = data?.allEmployees?.edges?.map(({node})=>{
                let date = []
                if(node?.issued?.edges[0]?.node?.createdAt){
                    date.push(node?.issued?.edges[0]?.node?.createdAt)
                }
                if(node?.received?.edges[0]?.node?.createdAt){
                    date.push(node?.received?.edges[0]?.node?.createdAt)
                }
                
                if(date?.length){ 
                    date = date?.sort((a,b)=>moment(b).unix()-moment(a).unix())
                }

                let last_accessed =  date?.length? displayUtcToUserTimezone( moment(date[0]), 'DD/MM/YYYY' ) :"-"
 
                let row_data = [
                    {
                        type:"label",
                        value:`${node?.user?.firstName} ${node?.user?.lastName || ""}`
                    },
                    {
                        type:"label",
                        value:node?.issued?.totalCount
                    },
                    {
                        type:"label",
                        value:node?.received?.totalCount
                    },
                    {
                        type:"label",
                        value:last_accessed
                    }
                ]
                return row_data
            })
            if(filter?.after){
                list = list_ref?.current?.concat(list)
            }
            SetList(list)
            SetPageInfo(data?.allEmployees?.pageInfo)
            setDataListLoading(false)
        }
    }

    const onPagination=()=>{
        if(page_info?.hasNextPage){
            ModuleUsage({after:page_info?.endCursor})
        }
    }

    return (
        <>
            <CommonTableView {...props} data_list_loading={data_list_loading} header_list={header_list} list={list} page_info={page_info} onPagination={onPagination}/>
        </>
    )
}

export default compose(withApollo)(KudosModuleUsageContainer)