import gql from "graphql-tag";

export const OKR_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
      owner:okrContributorSet(role:"OWNER"){
    	  totalCount
      }
      collabrator:okrContributorSet(role:"COLLABORATOR"){
        totalCount
      }
      assigned:okrContributorSet(role:"ASSIGNED"){
        totalCount
      }
      accepted_okr:okrContributorSet(role_In:["COLLABORATOR","ASSIGNED"],requestStatus:"ACCEPTED"){
        totalCount
      }
      last_accessed:okrContributorSet(requestStatus:"ACCEPTED",orderBy:["updatedAt"],last:1){
 		    edges{
          node{
            id
            updatedAt
          }
        }
      }
    }
  }
 }
}
`

export const SOUL_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
        values:responseSet(survey_In:[3]){
          totalCount
        }
        strength:responseSet(survey_In:[7]){
          totalCount
        }
        personality:responseSet(survey_In:[8]){
          totalCount
        }
        knowledge:responseSet(survey_In:[9]){
          totalCount
        }
        impact_narrative:impactnarrativeSet(last:1){
          totalCount
          edges{
            node{
              id
              createdAt
              updatedAt
            }
          }
        }
        last_accessed:responseSet(survey_In:[3,7,8,9],last:1){
          edges{
            node{
              id
              responseDate
            }
          }
        }
      }
    }
  }
 }
}
`

export const IDP_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
        total_idp: idpSet(status_In: ["APPROVED", "TODO", "INPROGRESS", "COMPLETED", "PEDNING_APPROVAL", "REVISE","DRAFT"],last:1) {
          totalCount
          edges{
            node{
              id
              updatedDt
            }
          }
        }
        completed: idpSet(status_In: ["COMPLETED"]) {
          totalCount
        }
        inprogress: idpSet(status_In: ["APPROVED", "TODO", "INPROGRESS"]) {
          totalCount
        }
        pending: idpSet(status_In: ["PEDNING_APPROVAL"]) {
          totalCount
        }
        revise: idpSet(status_In: ["REVISE"]) {
          totalCount
        }
          draft: idpSet(status_In: ["DRAFT"]) {
          totalCount
        }
    }
  }
 }
}
`

export const MEETING_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$when_Gt: DateTime){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
 	      total_meeting:meetingMemberSet(last:1){
        	totalCount
          edges{
            node{
              id
              updatedAt          
            }
          }
        }
        completed:meetingMemberSet(endedAt_Isnull:false,last:1){
        	totalCount
          edges{
            node{
              id
              endedAt
            }
          }
        }
        upcoming:meetingMemberSet(when_Gt:$when_Gt){
          totalCount
        }
    }
  }
 }
}
`

export const READINESS_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
    }
  }
 }
}
`

export const FEEDBACK_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
        given:feedbackGiven(last:1){
          totalCount
          edges{
            node{
              updatedAt
            }
          }
        }
        received:feedbackReceived{
          totalCount
        }
    }
  }
 }
}
`

export const KUDOS_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
				received:recevedBadgeAwardSet(first:1){
          totalCount
            edges{
            node{
              createdAt
            }
          }
        }
        issued:issuedBadgeAwardSet(first:1){
          totalCount
          edges{
            node{
              createdAt
            }
          }
        }
    }
  }
 }
}
`

export const GROW_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String){
 allEmployees(first:$first,after:$after){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
				coachee(last:1){
          totalCount
          edges{
            node{
              id
              createdAt
            }
          }
        }
        coaching(last:1){
          totalCount
          edges{
            node{
              id
              createdAt
            }
          }
        }
        incomplete_coachee:coachee{
          edges{
            node{
              id
              actionItems(status_Ne:"COMPLETED"){
                totalCount
              }
            }
          }
        }
        incomplete_coaching:coaching{
          edges{
            node{
              id
              actionItems(status_Ne:"COMPLETED"){
                totalCount
              }
            }
          }
        }
    }
  }
 }
}
`