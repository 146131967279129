import { Button, Input, Spin, Tooltip } from "antd";
import { SURVEY_ID_3CS } from "../../../../config";
import { displayUtcToUserTimezone, getIntFromString } from "modules/look";
import { all_survey_icon } from "modules/survey-module/icon";
import survey_module_route from "modules/survey-module/routes";
import React from "react";
import survey_route from "modules/survey/route";


const ReportView = (props) => {

  const { all_response, navigateRoute, response_loading, page_info, getResponseList } = props

  let search_ref = React.useRef("")
  let custom_report_available = [SURVEY_ID_3CS]
  const navigateToReport = (data) => {
    if(getIntFromString(data?.survey?.id) == SURVEY_ID_3CS){
      navigateRoute(`${survey_route?.report3cs}`)
    }
    else if(data?.report_tool_id){
      navigateRoute(survey_module_route?.report_tool?.replace(":id", data?.report_tool_id)?.replace(":response_id",getIntFromString(data?.id)))
    }

  }

  const navigateTo360Invite = (data) => {
    let url = survey_module_route.survey_360?.replace(":surveyid", getIntFromString(data?.survey?.id))?.replace(":responseid", getIntFromString(data?.id))
    navigateRoute(url)
  }

  const navigateTo360Report = (data) => {
    let url = survey_module_route.survey_360_report?.replace(":user_id", getIntFromString(data?.user?.employee?.id))?.replace(":responseid", getIntFromString(data?.id))
    navigateRoute(url)
  }

  const onPagination = (data) => {
    if (data?.hasNextPage) {
      let filter = { after: data?.endCursor }
      if (search_ref?.current) {
        filter.survey_Name_Icontains = search_ref?.current
      }
      getResponseList(filter)
    }
  }

  let search_time = React.useRef()
  const searchSurvey = (e) => {
    if (search_time.current) {
      clearTimeout(search_time?.current)
    }
    search_time.current = setTimeout(() => {
      search_ref.current = e
      getResponseList({ survey_Name_Icontains: e })
    }, 500);
  }

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }} >
        <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
          <img src={all_survey_icon?.search_icon} alt="search_icon" style={{ height: "20px" }} />
          <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search" onChange={(e) => searchSurvey(e?.target?.value)} />
        </div>
      </div>
      <div className='org-table-container' style={{ padding: "1em" }}>
        <Spin spinning={response_loading}>
          <table className='org-table'>
            <thead>
              <tr className='org-row-header'>
                <th className='org-table-row-padding-left org-left-radious' style={{ textAlign: "left", width: "25%" }}>
                  <h4 className='org-table-header-label' >Surveys</h4>
                </th>

                <th className='org-table-header-label' style={{ textAlign: "center" }}>
                  <h4 className='org-table-header-label'>Response date </h4>
                </th>

                <th className='org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious'>
                  <h4 className='org-table-header-label'>Actions</h4>
                </th>
              </tr>
            </thead>
            <tbody>

              {all_response?.length > 0 ? all_response?.map(item => (
                <tr className='org-row-body'>
                  <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                    <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.survey?.name}</h4>
                  </td>
                  <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                    <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.responseDate ? displayUtcToUserTimezone(item?.responseDate, 'MMM DD YYYY, hh:mm A') : '-'}</h4>
                  </td>

                  <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5em", width: "100%", justifyContent: "center" }}>
                      
                      {((item?.report_tool_id) || custom_report_available?.includes(getIntFromString(item?.survey?.id))) ?
                        <Tooltip title={"View Report"}>
                          <Button className="report-action-btn" onClick={() => navigateToReport(item)}><img src={all_survey_icon?.report_icon} alt="report_icon" /></Button>
                        </Tooltip> :
                        <Button className="report-action-btn-disabled"  ><img src={all_survey_icon?.report_icon} alt="report_icon" /></Button>

                      }

                      {
                        (item?.survey?.multisourceResponse) ?
                          <>
                            <Tooltip title={"360 Invite"}>
                              <Button className="report-action-btn" onClick={() => navigateTo360Invite(item)}><img src={all_survey_icon?.invite_360} alt="360_invite" /></Button>
                            </Tooltip>
                            <Tooltip title={"360 Report"}>
                              <Button className="report-action-btn" onClick={() => navigateTo360Report(item)}><img src={all_survey_icon?.report_360} alt="360_report" /></Button>
                            </Tooltip>
                          </> :
                          <>
                            <Button className="report-action-btn-disabled"><img src={all_survey_icon?.invite_360} alt="360_invite" /></Button>
                            <Button className="report-action-btn-disabled"><img src={all_survey_icon?.report_360} alt="360_report" /></Button>
                          </>
                      }
                    </div>
                  </td>
                </tr>
              )) : null}
            </tbody>
          </table>
        </Spin>
        {(!response_loading && all_response?.length && page_info?.hasNextPage) ? <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
          <h4 onClick={() => { onPagination(page_info) }} className='pagination-label' style={{ cursor: "pointer", margin: "0px", padding: "0px" }}>View More</h4>
        </div> : ''}
      </div>
    </>
  )

}

export default ReportView;

