import React from "react";
import '../../css/poc-dashboard-style.css'
import okrModuleUsageContainer from "./usage_module/okrModuleUsageContainer";
import { globalPermissionValidator } from "modules/look";
import { poc_admin_permission } from "Permissions/poc_admin.permission";
import { Select, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import soulModuleUsageContainer from "./usage_module/soulModuleUsageContainer";
import idpModuleUsageContainer from "./usage_module/idpModuleUsageContainer";
import meetingModuleUsageContainer from "./usage_module/meetingModuleUsageContainer";
import readinessModuleUsageContainer from "./usage_module/readinessModuleUsageContainer";
import feedbackModuleUsageContainer from "./usage_module/feedbackModuleUsageContainer";
import KudosModuleUsageContainer from "./usage_module/kudosModuleUsgaeContainer";
import GrowModuleUsageContainer from "./usage_module/coachingConvModuleUsageContainer";
import MappReviewContainer from "./usage_module/MappReviewContainer";
import { ca_create_icon } from "modules/competency-assessment/icon";

const ModuleUsageReportContainer = (props) => {
    const { match, me, userPermission, location } = props
    const [permission, setPermission] = React.useState()

    let tab_list = [
        {
            key: "mapp_review",
            label: "Mapp Overview"
        },
        {
            key: "module_usage",
            label: "Module Usage"
        }
    ]

    const [current_tab, SetCurrentTab] = React.useState(tab_list?.find(i => i?.key == match?.params?.tab) || {
        key: "mapp_review",
        label: "Mapp Review"
    })
    const [selected_module, SetModule] = React.useState("soul")
    const module = {
        okr: okrModuleUsageContainer,
        soul: soulModuleUsageContainer,
        idp: idpModuleUsageContainer,
        meeting: meetingModuleUsageContainer,
        readiness: readinessModuleUsageContainer,
        feedback: feedbackModuleUsageContainer,
        kudos: KudosModuleUsageContainer,
        coaching: GrowModuleUsageContainer
    }
    React.useEffect(() => {
        if (location?.search) {
            let params = new URLSearchParams(location.search)
            let module_tab = params?.get('module')
            if (module[module_tab]) {
                SetModule(module_tab)
            } else {
                SetModule("okr")
            }
        }
    }, [location])

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(poc_admin_permission, userPermission)

            if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
                permission.required_permission = false
            }
            if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
                permission.required_permission = false
            }

            setPermission(permission)
        }
    }, [userPermission, me])



    const CurrentView = module[selected_module]

    let module_list = [
        {
            label: "SOUL",
            key: "soul"
        },
        {
            label: "IDP",
            key: "idp"
        },
        {
            label: "OKR",
            key: "okr"
        },
        {
            label: "Readiness Zone",
            key: "readiness"
        },
        {
            label: "Feedback",
            key: "feedback"
        },
        {
            label: "Coaching Conversation",
            key: "coaching"
        },
        {
            label: "Kudos",
            key: "kudos"
        },
        {
            label: "1:1 Meeting",
            key: "meeting"
        }
    ]

    return (
        <>
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}

            {
                (permission && permission?.required_permission) && (
                    <div className="poc-org-root module-usage-root-style"  >

                        <div className='poc-org-nav-view'>
                            {
                                tab_list?.map(sidebar => (

                                    <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`} onClick={() => SetCurrentTab(sidebar)}>
                                        <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                                    </div>
                                ))
                            }
                            <div className='border-line'>

                            </div>
                        </div>
                        <div className="module-usage-outlet">
                            {
                                current_tab?.key == "module_usage" ?
                                    <div style={{ width: "100%", marginTop: "1em" }}>
                                        <div style={{ width: "220px" }}>
                                            <Select
                                                className="rd-select-filed rd-type-select"
                                                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                                                suffixIcon={<img src={ca_create_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                                showSearch={false}
                                                defaultValue={selected_module}
                                                onSelect={(e) => { SetModule(e) }}
                                                filterOption={false}
                                                dropdownClassName="content-dropdown"
                                                {...props}
                                            >
                                                {module_list?.map(value => (
                                                    <Select.Option
                                                        className='rd-select-option'
                                                        key={value?.key}
                                                        value={value?.key}
                                                    >
                                                        {value?.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div> : ""
                            }

                            {
                                current_tab?.key == "mapp_review" ?
                                    <MappReviewContainer {...props} permission={permission} /> : CurrentView ? <CurrentView  {...props} permission={permission} /> : ""
                            }

                        </div>

                    </div>
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }

        </>
    )
}

export default ModuleUsageReportContainer;