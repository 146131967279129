import { Button, Select, Spin, Tooltip } from "antd";
import { cc_graduation_test } from "../../../../config";
import { displayUtcToUserTimezone, exportToCsv, getIntFromString } from "modules/look";
import { dashboard_home_icons, organization_icons } from "modules/poc-dashboard/icons";
import poc_dashboard_routes from "modules/poc-dashboard/routes";
import React from "react";
import styled from "styled-components";
import arrow_down from "../../../assets/arrow_down.svg"
import arrow_up from "../../../assets/arrow_up.svg"
import Growpagination from "modules/grow-model/components/growPagination";
import moment from "moment";
const PocReprtListView = (props) => {
    const { org_survey_lists, selected_survey_response, getSelectedSurveyResponse, table_data_loading, navigateRoute, sortScore, total_score, response_page_info } = props
    const [filter, setFilter] = React.useState()
    const [defult_filter, setDefaultFilter] = React.useState()
    const [export_table_data, setExportTableData] = React.useState([])
    const [table_data, setTableData] = React.useState([])
    React.useEffect(() => {
        if (selected_survey_response) {
            setTableData(selected_survey_response)
        }
    }, [selected_survey_response])

    React.useEffect(() => {
        if (org_survey_lists?.length > 0) {
            let default_value = org_survey_lists?.find(item => cc_graduation_test.includes(getIntFromString(item?.id)))
            let selected_survey = JSON.parse(localStorage.getItem('selected_survey_response'))
            if (selected_survey) {
                setFilter({ id: selected_survey?.id, name: selected_survey?.name })
                setDefaultFilter(selected_survey)
            }
            else {
                if (default_value) {
                    setFilter({ id: default_value?.id, name: default_value?.name })
                    setDefaultFilter(default_value)
                }
                else {
                    setFilter({ id: org_survey_lists[0]?.id, name: org_survey_lists[0]?.name })
                    setDefaultFilter(org_survey_lists[0])
                }
            }

        }
    }, [org_survey_lists])
    React.useEffect(() => {
        if (filter) {
            getSelectedSurveyResponse({ survey: filter?.id })
        }
    }, [filter])
    React.useEffect(() => {
        if (selected_survey_response?.length) {
            let export_data = selected_survey_response.map((item,index) => ({
                ["Sr.No"]: index+1,
                Name: `${item?.firstName} ${item?.lastName}`,
                Email: item?.email,
                ['Response Date']: displayUtcToUserTimezone(item?.responseDate, 'MMM DD YYYY, hh:mm'),
                ...(cc_graduation_test.includes(getIntFromString(filter?.id)) && { [`Score (Maximum Score: ${total_score})`]: item?.totalScore })
            }));
            setExportTableData(export_data)
        }
    }, [selected_survey_response])

    const seeReport = (item) => {
        localStorage.setItem('selected_survey_response', JSON.stringify(filter))
        navigateRoute(poc_dashboard_routes?.poc_user_report.replace(':survey_id', getIntFromString(filter?.id)).replace(':response_id', getIntFromString(item?.response_id)))
    }
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                <img src={organization_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => { localStorage.removeItem('selected_survey_response'); navigateRoute("-1") }} />
                <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600" }}>Survey Reports</h4>
            </div>
            <div className="poc-org-root poc-org-root-style" style={{ height: "100%", maxHeight: "100%", marginTop: '1em' }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", flexWrap: "wrap", marginTop: "1em",maxWidth:"98%" }}>
                    <div></div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyItems: "center", alignItems: "center", gap: "1em", padding: '1em'}}>
                        <h4 className="poc-dashboard-card-title">Survey Report</h4>
                        {org_survey_lists?.length > 0 && defult_filter&& <CustomSelectSurvey dropdownClassName="content-dropdown" placeholder="Select Survey" onChange={(e, item) => { setFilter({ id: item?.key, name: item?.value, is_360: item?.label }) }} defaultValue={defult_filter?.name}>
                            {org_survey_lists?.map(item => (
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key={item?.id} value={item?.name} label={item?.multisourceResponse}>{item?.name}</Select.Option>
                            ))}
                        </CustomSelectSurvey>}
                    </div>
                    {!table_data_loading && table_data?.length ? <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1em", justifyContent: 'start' }}>
                            <Button className="export-btn" onClick={() => exportToCsv(`MApp_${filter?.name}_score_sheet_${moment()}`, export_table_data)}><img src={dashboard_home_icons?.export_icon} alt="export_icon" style={{ height: "1.25em", marginRight: "1em" }} /> Export</Button>
                            {cc_graduation_test.includes(getIntFromString(filter?.id)) && <h4 className="org-table-header-label" style={{ color: "#000" }}>Max Score : {total_score}</h4>}
                        </div>
                    </div> : <div></div>}
                </div>
                <Spin spinning={table_data_loading}>
                    <div className='org-table-container poc_table_scroll container-alignment' style={{ width: '100%', padding: "0em 2em", marginTop: "0em" }}>
                        <table className='org-table'>
                            <thead>
                                <tr className='org-row-header'>
                                    <th className='org-table-row-padding-left org-left-radious' >
                                        <h4 className='org-table-header-label'>Name</h4>
                                    </th>
                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='org-table-header-label'>Email</h4>
                                    </th>
                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='org-table-header-label'>Response Date</h4>
                                    </th>
                                    {cc_graduation_test.includes(getIntFromString(filter?.id)) && <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', gap: '1em' }}>
                                            <h4 className='org-table-header-label'>Score</h4>
                                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center', gap: '.75em' }}>
                                                <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: ".55em", cursor: "pointer" }} onClick={() => sortScore("ascending")}>
                                                    <path d="M4.7 25L20 9.54791L35.3 25L40 20.2429L20 0L0 20.2429L4.7 25Z" fill="#fff" fill-opacity="1" />
                                                </svg>
                                                <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: ".55em", cursor: "pointer" }} onClick={() => sortScore("descending")}>
                                                    <path d="M4.7 0L20 15.4521L35.3 0L40 4.75709L20 25L0 4.75709L4.7 0Z" fill="#fff" fill-opacity="1" />
                                                </svg>
                                            </div>
                                        </div>
                                    </th>}
                                    <th className='org-table-header-label org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious' style={{ textAlign: "center" }}>
                                        <h4 className='org-table-header-label'>Action</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    table_data?.length > 0 && table_data?.map(item => (
                                        <tr className='org-row-body'>
                                            <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.firstName} {item?.lastName}</h4>
                                            </td>
                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.email}</h4>
                                            </td>
                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{displayUtcToUserTimezone(item?.responseDate, 'MMM DD YYYY, hh:mm')}</h4>
                                            </td>
                                            {cc_graduation_test.includes(getIntFromString(filter?.id)) && <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.totalScore}</h4>
                                            </td>}
                                            <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none", marginLeft: "1em" }}>
                                                <Tooltip title={"View Report"}>
                                                    <img src={dashboard_home_icons?.report_icon} alt="report_icon" style={{ cursor: "pointer" }} onClick={() => seeReport(item)} />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        {
                            (!table_data?.length && !table_data_loading) ?
                                <div className='rd-nodata' style={{ minHeight: "10em" }}>
                                    <h4 className='rd-nodata-label'>No data found</h4>
                                </div> : ''
                        }
                    </div>
                    {(response_page_info?.hasNextPage && response_page_info?.cursor) && <div align='center'>
                        <Growpagination on_pagination={() => getSelectedSurveyResponse({ survey: filter?.id, cursor: response_page_info?.cursor })} />
                    </div>}
                </Spin>
            </div>
        </div>
    )
}

export default PocReprtListView;
const CustomSelectSurvey = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.5em !important;
    width:25em !important;
    height: 40px !important;
    padding-top: 4px !important;
}
`;