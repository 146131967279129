import React from "react";
import '../css/userDashboard.css'
import { Button, Spin, Modal, Tooltip } from 'antd'
import { dasboardIcons } from "../dashboard-icons";
import OkrPerformanceContainer from '../containers/okrPerformanceContainer'
import CoachingConversationContainer from '../containers/coachingCoversationContainer'
import KudosAndFeedbackContainer from "../containers/kudosAndFeedbackContainer";
import UserOneOnOneContainer from "../containers/userOneOnOneContainer";
import moment from "moment-timezone";
import { learning_path_time_limit, SURVEY_DIVERSITY_PROFILE, SURVEY_WHOPOSHORO } from '../../../config';
import Survey360ResponseContainer from "../containers/360SurveyResponseListContainer";
import { MetaTags } from "modules/look";
import mapp_learning_path_routes from "modules/learning-path/routes";
import { learningConfig } from "modules/learning-path/configs/learning-config";

const UserDasboardView = (props) => {
    const { taken = false, me, checkSurveyStatus, user_permission, dashboard_loading, soul_tip, taken_survey,navigateRoute } = props
    const [preview_tip, SetPreviewTip] = React.useState()
    const getGreeting = () => {
        const currentTime = moment();
        const hour = currentTime.hours();
        if (hour >= 3 && hour < 12) {
            return 'Good Morning';
        } else if (hour >= 12 && hour < 17) {
            return 'Good Afternoon';
        } else if (hour >= 17 && hour < 21) {
            return 'Good Evening';
        } else {
            return '';
        }
    }

    const soulTipPreview=()=>{
        if(soul_tip && soul_tip?.length){
            SetPreviewTip(soul_tip[0])
        }
    }

    return (
        <>
            <MetaTags title="Dashboard" description="User dashboard" />
            <div className="root-user-container">
                <Spin spinning={dashboard_loading}>
                    <div className="user-dashboard-container">
                        <div className="user-dasboard-left-container">
                            {user_permission && <div>
                                {/* {moment(me?.employee?.createdAt).add(learning_path_time_limit, 'days').isBefore(moment()) || soul_tip?.length? */}
                                <div className={`user-dasboard-banner-for-survey-taken ${soul_tip?.length?'soul-tip-available-effect':''}`} onClick={() =>soulTipPreview() }>
                                    <div className="dashboard-banner-section-container">
                                        <h4 className="user-board-welcome-banner-heading-taken">Welcome to MApp! </h4>
                                        <h4 className="user-board-welcome-greeting-taken">{getGreeting()} {me?.employee?.user?.firstName}!</h4>
                                        {soul_tip?.length > 0 &&
                                            <div className="baner-tip-container" style={{ marginTop: '1em', width: "100%" }}>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".5em" }}>
                                                    <img src={dasboardIcons?.tip_icon} alt="" />
                                                    <h4 className="baner-tip-title" >Soul tip of the day</h4>
                                                </div>
                                                <div className="soul-tip-one-on-one-tool soul-tip-dashboard">
                                                    <div>
                                                        <p className="baner-tip-content" >{soul_tip[0]}</p>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <img className="dashboard-banner-image" src={dasboardIcons?.baner_bg} alt="" />
                                </div> 
                                {/* :
                                    <div className="user-dasboard-welcome-banner">
                                        <h4 className="user-board-welcome-banner-heading">Welcome to Mapp learning path</h4>
                                        <p className="user-board-welcome-banner-para">{learningConfig?.dashboard_landing_content}</p>
                                        <Tooltip title="Learning Path: Start your leaning journey with a guided steps and explanation">
                                            <Button className="user-board-welcome-banner-button" style={{textTransform:"uppercase"}} onClick={()=>navigateRoute(mapp_learning_path_routes?.learning_path)}>Getting started </Button>
                                        </Tooltip>   
                                    </div>
                                } */}
                            </div>}
                            <Modal
                                visible={preview_tip}
                                footer={false}
                                closable={false}
                                centered={true}
                                destroyOnClose={true}
                                width={"90%"}
                                style={{ maxWidth: "600px" }}
                            >
                                <div style={{ width: "100%", height: "100%" }}>
                                    <div style={{ display: "flex", justifyContent: "end", width: "100%", cursor: "pointer" }}>

                                        <img src={dasboardIcons?.close_icon} alt="" onClick={() => { SetPreviewTip(null) }} />
                                    </div>
                                    <div className="soul-tip-preview-container">
                                        <img src={dasboardIcons.soul_tip_preview_gif} style={{width:"300px"}} alt="" />
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".5em" }}>
                                            <img src={dasboardIcons?.tip_icon} alt="" />
                                            <h4 className="baner-tip-title" style={{ cursor: "pointer", fontSize: "1.5em" }} >Soul tips </h4>
                                        </div>
                                       
                                        <h4 className="preview-tip-popup">{preview_tip}</h4>
                                    </div>

                                </div>
                            </Modal>
                            {
                                user_permission?.okr_permission && (
                                    <div>
                                        <OkrPerformanceContainer user_permission={user_permission} {...props} />
                                    </div>
                                )
                            }
                            {
                                user_permission?.coaching_engagement_permission && (
                                    <div>
                                        <CoachingConversationContainer user_permission={user_permission} {...props} />
                                    </div>
                                )
                            }
                            {
                                user_permission?.token_survey_permission && (
                                    <div>
                                        <Survey360ResponseContainer user_permission={user_permission} {...props} />
                                    </div>
                                )
                            }
                        </div>
                        <div className="user-dasboard-right-container">
                            <h4 className="user-board-section-heading">Activity</h4>
                            {taken_survey?.diversity === 0 && user_permission?.survey_permission && <div className="user-dasboard-small-card-container">
                                <h4 className="user-dashboard-small-card-title">Diversity Profile</h4>
                                <p className="user-dashboard-small-card-para">Diversity profile survey helps us gauge your uniqueness
                                    in every bit of your being. </p>
                                <Button className="user-board-welcome-banner-button" style={{ height: "2.5em", fontSize: "1.1em" }} onClick={() => checkSurveyStatus({ id: SURVEY_DIVERSITY_PROFILE, type: "Diversity", is_navigate: true })}>START SURVEY</Button>
                            </div>}
                            {taken_survey?.whoposhoro === 0 && user_permission?.survey_permission && <div className="user-dasboard-small-card-container">
                                <h4 className="user-dashboard-small-card-title">Whoposhoro</h4>
                                <p className="user-dashboard-small-card-para">WhoPoShoRo assesses your unique communication style, self-management, and public perception.</p>
                                <Button className="user-board-welcome-banner-button" style={{ height: "2.5em", fontSize: "1.1em" }} onClick={() => checkSurveyStatus({ id: SURVEY_WHOPOSHORO, type: "Whoposhoro", is_navigate: true })}>START SURVEY</Button>
                            </div>}
                            {user_permission?.oneonone_permission && <div>
                                <UserOneOnOneContainer user_permission={user_permission}{...props} />
                            </div>}
                            {(user_permission?.kudos_permission || user_permission?.feedback_permission) && <div>
                                <KudosAndFeedbackContainer user_permission={user_permission} {...props} />
                            </div>}

                        </div>
                    </div>
                </Spin>
            </div>
        </>
    )
}

export default UserDasboardView