import React from 'react';
import { Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import poc_dashboard_routes from 'modules/poc-dashboard/routes';
import { getIntFromString } from 'modules/look'; 
import { dashboard_home_icons } from 'modules/poc-dashboard/icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const PocDashboartBarChart =(props)=>{
    const {filter,graph_data_list,navigateRoute,requiredNavigation,table_loading,noDataMessage,fetchTeamData} = props
    const [data_list,setDataList]=React.useState([])

    
    React.useEffect(()=>{
        if(graph_data_list?.length){
            setDataList(graph_data_list)
        }
        else{
            setDataList([])
        }
    },[graph_data_list])


    const chartData = {
        labels:data_list?.map(i=>i.title),
        datasets: [
            {
                data: data_list?.map(i=>i.value),
                backgroundColor: data_list?.map(i=>i.color),
                
                barThickness: 20, // Adjust the bar width
                borderRadius: 8, // Set the border radius for all four corners
                categoryPercentage: 0.9,
            }
        ]
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, elements) => {
            if (elements.length > 0&&requiredNavigation) {
              const firstElement = elements[0];
              const index = firstElement?.index;
              const selectedItem = data_list[index];
              localStorage.setItem("poc_report_vertical_id",getIntFromString(selectedItem.id))
              localStorage.setItem("poc_report_survey_id",getIntFromString(filter.id))
              navigateRoute(poc_dashboard_routes?.poc_vertical_detail.replace(":id",getIntFromString(selectedItem.id)).replace(":survey_id",getIntFromString(filter?.id)));
            }
            else if(elements.length > 0){
                const firstElement = elements[0];
                const index = firstElement.index;
                const selectedItem = data_list[index];
                fetchTeamData(selectedItem)
            }
          },
        scrollX: {
            enabled: true
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: 100,
                ticks: {
                    color: '#000',
                    font: {
                        size: 13,
                        weight:600,

    
                    }
                },
                grid: {
                    display: true,
                    color:"rgba(201, 208, 218, 1)",
                    borderDash: [8, 8],
                }
            },
            x: {
                beginAtZero: true,
                barPercentage: 0.5,
                categoryPercentage: 0.7,
                grid: {
                    display: false,
                    offset: false
                },
                ticks: {
                    color: '#000',
                    font: {
                        size: 13,
                        weight:600
                    }
                }
            }
        },
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 0,
                borderHeight: 0
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            tooltip: {
                backgroundColor:'#0E0E2C',
                callbacks: {
                    label: function (context) {
                        
                        let doc =data_list[context?.dataIndex]?.value % 1===0?`${data_list[context?.dataIndex]?.value}%`:`${data_list[context?.dataIndex]?.value?.toFixed(2)}%`
                        if(doc?.length>100){
                            doc = doc?.slice(0,100)+'...'
                        }
                        return doc||''
                        ;
                    }
                }
            },
            title: {
                display: false,
            }
        },
    };
    return (
        <div style={{marginTop:"1em",width:"100%",height:'100%'}}>
            <div style={{display:'flex',flexDirection:"row",justifyContent:"start",alignItems:'center',gap:"1em"}}>
                {filter?.is_360&&<img src={dashboard_home_icons?.icon_360} alt="" />}
                <h4 style={{fontSize:"1em",fontFamily:"Poppins",fontWeight:"700",fontStyle:"normal",color:"#4CCB1F",margin:"0"}}>{filter?.name}</h4>
            </div>
            {data_list?.length>0?<div className='ca-scroll-container'style={{marginTop:'1em'}}>
            <div className='poc_bar_chart_container' style={{height:`${34*(data_list?.length || 1)}px`}}>
                <Bar width="100%" data={chartData} options={options} />
            </div>
            </div>:
            !table_loading&&<div style={{width:"100%",display:'flex',flexDirection:"column", justifyContent:'center',alignItems:'center',height:"50vh"}}><h4 className='no-data-label' style={{fontSize:'1em'}}> {noDataMessage} </h4></div>}
        </div>
        
    )

}

export default PocDashboartBarChart
